<template>
  <div>
    <!-- 搜索框 -->
    <div class="header">
      <el-input
        id="input1"
        v-model="search"
        placeholder="请输入内容"
      ></el-input>
      <el-button type="primary">查询</el-button>
      <!-- <el-button type="primary">添加</el-button> -->
    </div>
    <!-- 表格 -->
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="康康ID" width="180"></el-table-column>
        <el-table-column
          prop="name"
          label="用户姓名"
          width="180"
        ></el-table-column>
        <el-table-column prop="userarea" label="用户区域"></el-table-column>
        <el-table-column prop="tell" label="联系电话"></el-table-column>
        <el-table-column prop="healthdata" label="健康数据"></el-table-column>
        <el-table-column prop="consumption" label="服务消费"></el-table-column>
        <el-table-column prop="time" label="激活日期"></el-table-column>
        <el-table-column prop="operation" label="操作">
          <template>
            <el-button
              class="details"
              size="mini"
              @click="dialogVisible = true"
            >
              <router-link to="/dictionary/SonDictionary">详情</router-link>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="buttons">
        <!-- 分页 -->
        <el-pagination
          :page-size="pagesize"
          :pager-count="pagercount"
          layout="slot, pager"
          :total="100"
        >
          <img class="lefts" src="../../assets/images/leftpage.png" alt />
          <img class="rights" src="../../assets/images/rightpage.png" alt />
        </el-pagination>
        <!-- 按钮 -->
        <div class="annius">
          <el-button>数据分析</el-button>
          <el-button>数据导出</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      pagesize: 1,
      pagercount: 5,
      //表格数据
      tableData: [
        {
          id: "518448",
          name: "红红红",
          userarea: "北京市 海淀区",
          time: "2020-03-15",
          healthdata: "全部正常",
          consumption: "￥489.23",
          tell: "14789564258",
        },
        {
          id: "518448",
          name: "红红红",
          userarea: "北京市 海淀区",
          time: "2020-03-15",
          healthdata: "全部正常",
          consumption: "￥489.23",
          tell: "14789564258",
        },
        {
          id: "518448",
          name: "红红红",
          userarea: "北京市 海淀区",
          time: "2020-03-15",
          healthdata: "全部正常",
          consumption: "￥489.23",
          tell: "14789564258",
        },
        {
          id: "518448",
          name: "红红红",
          userarea: "北京市 海淀区",
          time: "2020-03-15",
          healthdata: "全部正常",
          consumption: "￥489.23",
          tell: "14789564258",
        },
        {
          id: "518448",
          name: "红红红",
          userarea: "北京市 海淀区",
          time: "2020-03-15",
          healthdata: "全部正常",
          consumption: "￥489.23",
          tell: "14789564258",
        },
        {
          id: "518448",
          name: "红红红",
          userarea: "北京市 海淀区",
          time: "2020-03-15",
          healthdata: "全部正常",
          consumption: "￥489.23",
          tell: "14789564258",
        },
        {
          id: "518448",
          name: "红红红",
          userarea: "北京市 海淀区",
          time: "2020-03-15",
          healthdata: "全部正常",
          consumption: "￥489.23",
          tell: "14789564258",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  margin-bottom: 30px;
  margin-left: 20px;
  button {
    margin-left: 20px;
  }
}
.header /deep/ #input1 {
  width: 30%;
}
// table
.tables {
  margin-right: 60px;
  margin-left: 20px;
  .el-table::before {
    height: 0;
  }
  .el-table {
    td,
    th.is-leaf {
      border: none !important;
    }
    tr th {
      font-size: 19px;
      color: #a8a8a8;
      text-align: center;
    }
    tr td {
      text-align: center;
      font-size: 17px;
      color: #a8a8a8;
    }
    .details {
      height: 30px;
      border-radius: 15px;
    }
  }
  // 按钮  分页
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 70px;
    .annius {
      .el-button {
        width: 145px;
        height: 50px;
        border-radius: 25px;
        margin-top: 20px;
        margin-left: 20px;
        background: #d78673;
        color: #ffffff;
        font-size: 19px;
      }
    }
    // 分页
    .el-pagination {
      padding: 0;
      margin-top: 20px;
      .el-pager,
      .el-pager li {
        margin-top: 2px;
        color: #afafaf;
        min-width: 50px;
        font-size: 17px;
        font-weight: normal;
      }
      .el-pager li.active {
        color: #d78673;
      }
      .el-pager li:hover {
        color: #d78673;
      }
      img {
        display: inline-block;
      }
      .lefts {
        margin-right: 25px;
      }
      .rights {
        float: right;
        margin-left: 25px;
      }
    }
  }
}
/deep/ .el-table .el-table__cell {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center;
}
/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/ th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/.el-input__inner {
  border-radius: 0;
  height: 33px;
  line-height: 33px;
}
/deep/.el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  transition: all 0.3s;
  line-height: 33px;
}
/deep/.el-form-item__label {
  color: #909399;
  text-align: center;
}
/deep/.el-dialog__body {
  padding: 0;
  margin-left: 2%;
}
/deep/.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 60%;
}
// .pagination {
//   text-align: center;
//   margin-top: 20px;
// }
</style>